.wardrobe-container {
  margin: 0 auto;
  overflow: auto;
  background: url("../../../../assets/game/wardrobe.png") no-repeat;
  height: 520px;
  width: 384px;

  .cabinet {
    margin: 39px 36px 0 56px;
    border: 3px solid transparent;
    height: 411px;

    .slot {
      margin: 5px 8px 3px;
    }
  }
  .panels {
    height: 38px;
    margin: 1px 30px 0 43px;

    .drawer {
      background-color: transparent;
      background-repeat: no-repeat;
      background-image: url("../../../../assets/game/wardrobe_active_drawers.png");
      width: 65px;
      height: 27px;
      float: left;
      margin: 4px 4px 5px 5px;
      cursor: pointer;
      opacity: 0;
      transition: opacity ease 0.3s;
      border: 0;
      outline: 0;
      &.active {
        opacity: 1;
      }
      &:nth-of-type(2) {
        background-position: -74px 0;
      }
      &:nth-of-type(3) {
        background-position: -148px 0;
      }
      &:nth-of-type(4) {
        margin-right: 0;
        background-position: -222px 0;
      }
    }
  }
}
