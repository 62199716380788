.game-content {
  > main {
    padding: 2rem 2rem;
  }
}
.pergament-container {
  position: relative;
  width: pxToRem(366px);
  height: pxToRem(516px);
  background: url("../../assets/pergament.png");
  background-size: contain;
  color: #000;

  > .inner {
    padding: 19px 54px 48px 43px;
  }
}
.relief {
  background: url("../../assets/game/relief_bg.png") no-repeat 4px 0;
  background-size: contain;
  overflow: hidden;
  height: pxToRem(560px);
  width: pxToRem(369px);
  color: #000;
  padding: 10px 25px 21px 19px;
}
.relief-inner {
  clear: both;
  background: url("../../assets/game/relief_innerblock.png") no-repeat;
  background-size: contain;
  width: pxToRem(336px);
  height: pxToRem(76px);
  margin: 0 auto;
  padding: 15px;

  > * {
    width: 100%;
    height: 100%;
  }

  td:nth-of-type(2),
  td:nth-of-type(4) {
    width: 100%;
    text-align: right;
  }

  td:nth-of-type(3) {
    padding-left: 20px;
  }

  td {
    cursor: default;
  }
}
