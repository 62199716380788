$brown: #a98d4a;
$darkGreen: #304439;
$white: #e8eff1;
$orange: #EE5500;

$background: $darkGreen;

$primary: $darkGreen;
$secondary: $orange;

@import "./darkly";

// Bootstrap Variables
$body-bg: $background;
$font-family-base: "Merriweather", serif;
//$font-size-base: 11pt;
$link-color: $brown;

$toast-header-background-color: rgba($dark, .85);

.bg-dark {
  --bs-bg-opacity: 0.9;
}
