.menu-container {
  width: 129px;
  padding-left: 9px;

  > ul {
    padding: 0;
    margin: 0 0 15px;
    > li {
      display: block;
      padding: 0;
      margin-bottom: 3px;
    }
  }
}

.content {
  flex-grow: 1;
  padding: 0 32px 0 5px;
}

footer {
  a {
    color: inherit;
  }
}

@import "header";
@import "menu";
@import "content";
@import "loading";

.game-container {
  max-width: 850px;
}
