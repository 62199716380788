.char-select-item {
  cursor: pointer;

  & .delete {
    display: none;
    opacity: 0.6;
  }
  &:hover .delete {
    display: inline-block;
  }
}
