.equipment-slot {
  overflow: hidden;
  width: 72px;
  height: 72px;
  background: url("../../assets/game/character_slot.png") no-repeat;
  > .inner {
    margin: 3px 0 0 3px;
    padding: 7px 10px 11px 8px;
    &.empty {
      padding: 0;
      height: 67px;
      width: 67px;
    }
    &.filled {
      background: url("../../assets/game/item_bg.png");
    }
  }
  &.drop {
    position: relative;
    &:before {
      display: block;
      content: " ";
      position: absolute;
      top: 3px;
      right: 0;
      bottom: 0;
      left: 3px;
      background: rgba(0, 204, 0, 0.3);
    }
  }

  &.small {
    width: 49px;
    height: 49px;
    background: url("../../assets/game/character_slot_small.png") no-repeat;
    > .inner {
      padding: 6px 8px 6px 6px;
      &.empty {
        padding: 0;
      }
      &.filled {
        background: url("../../assets/game/item_small_bg.png");
      }
    }
  }
}
