.chat-position {
  position: absolute;
  bottom: 0;
  color: #fff;
  height: 210px;
  overflow: hidden;
  z-index: 20;
  left: 135px;
  transition: height 0.5s;

  &.min {
    height: 15px;
  }
}
.chat-offcanvas {
  min-height: 230px;
}
.chat-history {
  .more-up {
    &:before {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      content: " ";
      display: block;
      box-shadow: 0 0 2rem 0.5rem rgb(0 0 0 / 80%)
    }
  }
  .more-down {
    &:after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      content: " ";
      display: block;
      box-shadow: 0 0 2rem 0.5rem rgb(0 0 0 / 80%)
    }
  }
}
.chat-input-container {
  height: 23px;

  input {
    vertical-align: middle;
  }
}
.chat-input {
  width: 325px;
}
input[type="image"].chat-submit {
  width: 23px;
  height: 23px;
  border: 0;
  background: url("../../assets/game/small_buttond.png") no-repeat;
}


.chat-message {
  font-size: 0.8rem;
  vertical-align: middle;
  padding-left: 18px;
  text-indent: -18px;

  .ts {
    font-size: 0.7rem;
    min-width: 2.2rem;
    color: #999;
    display: inline-block;
    text-indent: 0;
    font-variant-numeric: ordinal;
    margin-right: 5px;
  }
  .name {
  }
  .c {
    //color: #88f;
  }
}

.chat-history {
  height: 172px;
}

.chat-toggle-container {
  position: absolute;
  left: 5px;

  button {
    display: block;
    width: 21px;
    height: 20px;
    position: relative;
    font-size: 0;
    border: 0;
    outline: 0;
    cursor: pointer;

    background: url("../../assets/game/chat_button_active.png") no-repeat;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: url("../../assets/game/chat_button.png") no-repeat;
      opacity: 1;
      transition: opacity 0.5s;
    }
    &.restore:before {
      opacity: 0;
    }
  }
}

.vertical-thumb {
  background: url("../../assets/genericbg.png");
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 1);
  border-radius: 3px;
}
