@function pxToRem($dividend) {
  @return add(divide($dividend, 16px), 0rem);
}

@mixin media-landscape-mobile($breakpoints: $grid-breakpoints) {
  $max: breakpoint-max(lg, $breakpoints);
  @media only screen and (max-width: $max) and (orientation: landscape) {
    @content;
  }
}
