.navbar-header {
  height: 4rem;
  box-shadow: 0 0.5em 1.5em rgb(0 0 0 / 20%);

  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;

  .sidebar-col {
  }

  > * {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
  .navbar-toggler-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .navbar-collapse.collapse.show {
    z-index: 1; // über dem bg-header shadow
  }
}
.bg-header {
  background-color: $brown;
  background-image: url("../../assets/textur.png");
  background-repeat: repeat;

  &:before {
    content: " ";
    position: absolute;
    top: 0;
    pointer-events: none;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    box-shadow: inset 0px -1.5rem 1.5rem -0.5rem rgba(0 0 0 / 30%)
  }
}

h1.logo {
  display: block;
  margin-bottom: 0;
  margin-block-end: 0;
  background: url("../../assets/logo.png") no-repeat;
  background-size: contain;
  width: pxToRem(313px);
  height: pxToRem(45px);

  @include media-breakpoint-down(sm) {
    width: pxToRem(260px);
    height: pxToRem(34px);
  }
  @media (max-width: 360px) {
    width: pxToRem(190px);
    height: pxToRem(28px);
  }
}
