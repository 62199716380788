@import url("https://fonts.googleapis.com/css?family=Merriweather:400,700");

html {
  //font-family: Montserrat, sans-serif;
  //font-family: "Merriweather", serif;
  //font-size: 11pt;

  height: 100%;

  @include media-landscape-mobile() {
    font-size: 11px;
  }
}
body {
  background-image: url("../assets/bg_v1.jpg");
  //display: flex;
  //justify-content: center;
  //color: #fff;
  min-height: 100%;
  //text-shadow: 0 0.05rem 0.1rem rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 5rem rgba(0, 0, 0, 0.5);

  display: flex;
  flex-direction: column;

  > #root {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
}


.app-connecting {
  background: url("../assets/textur.png") repeat;
  .bi {
    &.fa-spin {
      animation-name: spin;
      animation-duration: 3000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;

      @keyframes spin {
        from {
          transform:rotate(0deg);
        }
        to {
          transform:rotate(360deg);
        }
      }
    }
  }
}
