.game-menu {
}
.game-sidebar {
  //@include media-breakpoint-up(md) {
  position: sticky;
  top: 4rem;
  //z-index: 1000;
  height: calc(100vh - 4rem);
  //}

  .nav-link {
    padding: 0.5rem 1rem;
    white-space: nowrap;
  }

  // .py-3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
  // }
  @include media-breakpoint-down(md) {
    // .nav-flush.py-0 only on breakpoint xs-sm

    padding: 0;

    .nav-link {
      border-radius: 0;
    }
  }
}
