.inventory-container {
  margin: 0 auto;
  overflow: auto;
  background: url("../../assets/game/inventory_bg.png") no-repeat;
  height: 520px;
  width: 378px;

  > .inner {
    margin: 11px 15px 0 25px;
    border: 3px solid transparent;
    height: 484px;
    border-radius: 15px;
  }
}

.slot {
  width: 50px;
  height: 50px;
  float: left;
  margin: 5px;
  border: 1px solid #666;
  border-radius: 4px;

  &.drop {
    border-color: rgba(0, 131, 0, 0.8);
  }
}
