.loading-overlay {
  position: absolute;

  right: 0;
  bottom: 0;
  top: 0;
  left: 0;

  &:before {
    opacity: 0.7;
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #222;
    border-radius: 15px;
  }

  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;
}
