@import "../styles";

.drag-empty-image {
  z-index: -1000;
  position: absolute;
}

.item-symbol {
  //width: 48px;
  //height: 48px;
  text-align: center;
  vertical-align: middle;
  border-radius: 4px;

  > img {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

.border-symbol {
  @extend .item-symbol;
  border: 1px solid transparent;
  border-radius: 2px;

  @each $name, $color in $quality-colors {
    &.#{$name} {
      border-color: $color;
    }
  }

  &.dragging {
    opacity: 0.2;
  }
}

.character-symbol {
  @extend .border-symbol;
  border-radius: 2px;
}
.drag-symbol {
  @extend .border-symbol;
  width: 48px;
  height: 48px;
  opacity: 1;
  background: rgba(51, 51, 51, 0.9);
}
