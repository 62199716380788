@import "tooltip";
@import "bs-icons";

span.currency.credits {
  background-image: url("../../assets/coin_16.png");
  background-size: contain;
  display: inline-block;
  height: pxToRem(16px);
  width: pxToRem(16px);
  font-size: 0.00001px;
  vertical-align: middle;
}
