button.google-btn {
  width: 191px;
  height: 46px;
  background: url("../../assets/google/btn_google_signin_light_normal_web.png");
  font-size: 0;
  &:hover,
  &:focus {
    background: url("../../assets/google/btn_google_signin_light_pressed_web.png");
  }
  &:active {
    background: url("../../assets/google/btn_google_signin_light_focus_web.png");
  }
}
button.discord-btn {
  background-color: #4752c4;
  &:hover:not(.disabled):not([disabled]) {
    background-color: #4752c4;
  }
}
